<template>
  <div class="page-view">
    <retrieve-mob v-if="isMobile"></retrieve-mob>
    <retrieve-pc v-else></retrieve-pc>
  </div>
</template>

<script>
import RetrievePc from "./retrieve-pc.vue";
import RetrieveMob from "./retrieve-mob.vue";
export default {
  components: {
    RetrievePc,
    RetrieveMob,
  },
  metaInfo: {
    title: "忘记密码",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>

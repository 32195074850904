<template>
  <div class="login-mob">
    <div class="top">
      <div class="logo">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <div class="title">{{ setPassword ? "设置新密码" : "找回密码" }}</div>
    </div>
    <div class="main">
      <div v-if="!setPassword" class="form-wrap">
        <div class="tab-bar">
          <span :class="[active ? 'active' : '']" @click="active = true"
            >手机号验证</span
          >
          <span :class="[active ? '' : 'active']" @click="active = false"
            >邮箱验证</span
          >
        </div>
        <div class="form">
          <div v-if="active">
            <yl-input v-model="formData.phone" placeholder="请输入手机号"
              ><img src="../../assets/phone.png" alt=""
            /></yl-input>
            <p class="forget"></p>
            <yl-input
              v-model="formData.code"
              :phone="formData.phone"
              :code="true"
              codeType="1"
              placeholder="请输入验证码"
              ><img src="../../assets/code.png" alt=""
            /></yl-input>
            <p class="tip">*仅限中国大陆手机，其他地区请使用邮箱验证</p>
          </div>
          <div v-else>
            <yl-input
              v-model="passwordFormData.email"
              placeholder="请输入您的邮箱"
              ><img src="../../assets/name.png" alt=""
            /></yl-input>
            <p class="tip"></p>
            <yl-input
              v-model="passwordFormData.code"
              :email="passwordFormData.email"
              :code="true"
              verify="email"
              placeholder="请输入验证码"
              ><img src="../../assets/code.png" alt=""
            /></yl-input>
          </div>
        </div>
      </div>
      <div v-if="setPassword" class="set-password">
        <yl-input
          v-model="setPasswordFormData.password"
          type="password"
          placeholder="请输入您的新密码"
          ><img src="../../assets/password.png" alt=""
        /></yl-input>
        <yl-input
          v-model="setPasswordFormData.newPassword"
          type="password"
          placeholder="请再次输入您的新密码"
          ><img src="../../assets/password.png" alt=""
        /></yl-input>
      </div>
      <div class="btn-wrap">
        <button v-if="!setPassword" class="login" @click="next()">
          下一步
        </button>
        <button v-if="setPassword" class="login" @click="setSubmit()">
          确认修改
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import YlInput from "../../components/yl-input.vue";
import wechat from "../../utils/wxShare";
import {
  havePassword,
  directUpPassword,
  verification,
  wechatConfig,
} from "../../api/user";
export default {
  components: {
    YlInput,
  },
  data() {
    return {
      active: true,
      formData: {
        phone: "",
        code: "",
      },
      passwordFormData: {
        email: "",
        code: "",
      },
      setPassword: false,
      setPasswordFormData: {
        password: "",
        newPassword: "",
      },
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      passwordReg: /^[a-zA-Z0-9]{6,12}$/,
      emailReg:
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
    };
  },
  created() {
    this.getHavePassword();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    next() {
      verification({
        key: this.active ? this.formData.phone : this.passwordFormData.email,
        verificationCode: this.active
          ? this.formData.code
          : this.passwordFormData.code,
      }).then((res) => {
        if (res.code == 0) {
          this.setPassword = true;
        } else {
          this.$ylmessage.error(res.msg);
        }
      });
    },
    getHavePassword() {
      havePassword().then((res) => {
        if (res.code == 1244) {
          this.setPassword = true;
        }
      });
    },
    setSubmit() {
      for (var key in this.setPasswordFormData) {
        if (
          this.setPasswordFormData[key] != "0" &&
          !this.setPasswordFormData[key]
        ) {
          this.$ylmessage.error("请输入密码");
          return;
        } else {
          if (!this.passwordReg.test(this.setPasswordFormData[key])) {
            this.$ylmessage.error("密码由6~12位字母数字组合");
            return;
          }
        }
      }
      if (
        this.setPasswordFormData.password !=
        this.setPasswordFormData.newPassword
      ) {
        this.$ylmessage.error("密码不一致");
      } else {
        directUpPassword({
          key: this.active ? this.formData.phone : this.passwordFormData.email,
          password: this.setPasswordFormData.password,
          verificationCode: this.active
            ? this.formData.code
            : this.passwordFormData.code,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("密码设置成功");
            setTimeout(() => {
              this.$router.replace({ path: "/personal" });
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
            // setTimeout(() => {
            //   location.reload();
            // }, 1500);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.login-mob {
  height: 100%;
  background: url("../../assets/h5bg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 1.35rem;
    .logo {
      width: 1.85rem;
      img {
        width: 100%;
      }
    }
    .title {
      margin-top: 0.4rem;
      font-size: 0.16rem;
      color: #fff;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  .main {
    padding: 0 0.25rem;
    .form-wrap {
      background: #fff;
      //   height: 2.5rem;
      border-radius: 8px;
      overflow: hidden;
      .tab-bar {
        height: 45px;
        background: #eaeaea;
        span {
          display: inline-block;
          width: 50%;
          text-align: center;
          line-height: 45px;
          font-size: 14px;
          color: #999;
        }
        span.active {
          color: #000;
          font-weight: bold;
          background: #fff;
        }
      }
      .form {
        padding: 40px 20px;
        .tip {
          height: 16px;
          font-size: 12px;
          margin: 0 0 15px;
          color: #666;
        }
        .forget {
          height: 12px;
          font-size: 12px;
          color: #666;
          text-align: right;
        }
      }
    }
    .btn-wrap {
      // display: flex;
      // align-items: center;
      padding: 0.3rem 0;
      button {
        display: block;
        width: 100%;
        margin-bottom: 0.15rem;
        height: 0.4rem;
        border: none;
        border-radius: 0.2rem;
        background: rgba($color: #000000, $alpha: 0);
        font-weight: bold;
        font-size: 0.14rem;
      }
      .login {
        color: #fff;
        background: #1872bb;
      }
      .register {
        color: #1872bb;
        // background: #fff;
        border: 1px solid #1872bb;
      }
    }
    .set-password {
      height: 2.6rem;
      background: #fff;
      border-radius: 8px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .yl-input {
        width: 100%;
      }
      .yl-input:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
</style>

<template>
  <div class="login-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="login-box">
        <div class="title">找回密码</div>
        <div class="content">
          <div v-if="!setPassword">
            <div class="login-tab">
              <span :class="[active ? 'active' : '']" @click="active = true"
                >手机号验证</span
              >
              <span :class="[active ? '' : 'active']" @click="active = false"
                >邮箱验证</span
              >
            </div>
            <div class="form">
              <div v-if="active" class="form-code">
                <yl-input v-model="formData.phone" placeholder="请输入手机号"
                  ><img src="../../assets/phone.png" alt=""
                /></yl-input>
                <p class="tip"></p>
                <yl-input
                  v-model="formData.code"
                  :phone="formData.phone"
                  :code="true"
                  codeType="1"
                  placeholder="请输入验证码"
                  ><img src="../../assets/code.png" alt=""
                /></yl-input>
                <p class="tip">*仅限中国大陆手机，其他地区请使用邮箱验证</p>
              </div>
              <div v-else class="form-account">
                <yl-input
                  v-model="passwordFormData.email"
                  placeholder="请输入您的邮箱"
                  ><img src="../../assets/name.png" alt=""
                /></yl-input>
                <p class="tip"></p>
                <yl-input
                  v-model="passwordFormData.code"
                  :email="passwordFormData.email"
                  :code="true"
                  verify="email"
                  placeholder="请输入验证码"
                  ><img src="../../assets/code.png" alt=""
                /></yl-input>
                <p class="tip"></p>
              </div>
            </div>
          </div>
          <div v-if="setPassword" class="set-password">
            <p class="title">设置新密码</p>
            <yl-input
              v-model="setPasswordFormData.password"
              type="password"
              placeholder="请输入您的新密码"
              ><img src="../../assets/password.png" alt=""
            /></yl-input>
            <yl-input
              v-model="setPasswordFormData.newPassword"
              type="password"
              placeholder="请再次输入您的新密码"
              ><img src="../../assets/password.png" alt=""
            /></yl-input>
          </div>
          <div class="btn-wrap">
            <button v-if="!setPassword" class="login" @click="next()">
              下一步
            </button>
            <button v-if="setPassword" class="login" @click="setSubmit()">
              确认修改
            </button>
          </div>
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import YlInput from "../../components/yl-input.vue";
import { havePassword, directUpPassword, verification } from "../../api/user";
export default {
  components: {
    PcHeader,
    PcFooter,
    YlInput,
  },
  data() {
    return {
      active: true,
      formData: {
        phone: "",
        code: "",
      },
      passwordFormData: {
        email: "",
        password: "",
      },
      setPassword: false,
      setPasswordFormData: {
        password: "",
        newPassword: "",
      },
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      passwordReg: /^[a-zA-Z0-9]{6,12}$/,
      emailReg:
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
    };
  },
  created() {
    this.getHavePassword();
  },
  methods: {
    next() {
      verification({
        key: this.active ? this.formData.phone : this.passwordFormData.email,
        verificationCode: this.active
          ? this.formData.code
          : this.passwordFormData.code,
      }).then((res) => {
        if (res.code == 0) {
          this.setPassword = true;
        } else {
          this.$ylmessage.error(res.msg);
        }
      });
    },
    getHavePassword() {
      havePassword().then((res) => {
        if (res.code == 1244) {
          this.setPassword = true;
        }
      });
    },
    setSubmit() {
      for (var key in this.setPasswordFormData) {
        if (
          this.setPasswordFormData[key] != "0" &&
          !this.setPasswordFormData[key]
        ) {
          this.$ylmessage.error("请输入密码");
          return;
        } else {
          if (!this.passwordReg.test(this.setPasswordFormData[key])) {
            this.$ylmessage.error("密码由6~12位字母数字组合");
            return;
          }
        }
      }
      if (
        this.setPasswordFormData.password !=
        this.setPasswordFormData.newPassword
      ) {
        this.$ylmessage.error("密码不一致");
      } else {
        directUpPassword({
          key: this.active ? this.formData.phone : this.passwordFormData.email,
          password: this.setPasswordFormData.password,
          verificationCode: this.active
            ? this.formData.code
            : this.passwordFormData.code,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("密码设置成功");
            setTimeout(() => {
              this.$router.replace({ path: "/personal" });
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
            setTimeout(() => {
              location.reload();
            }, 1500);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-pc {
  height: 100%;
  background: url("../../assets/pcbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    margin-top: 30px;
    min-height: 550px;
    .login-box {
      width: 1200px;
      height: 470px;
      background-color: #fff;
      margin: 0 auto;
      .title {
        font-size: 20px;
        font-weight: 500;
        padding-left: 30px;
        height: 57px;
        line-height: 57px;
        border-bottom: 1px solid #ececec;
      }
      .content {
        width: 610px;
        margin: 36px auto 0;
        .login-tab {
          height: 60px;
          background-color: #ececec;
          span {
            cursor: pointer;
            display: inline-block;
            width: 50%;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            color: #999;
          }
          .active {
            color: #fff;
            background-color: #1872bb;
          }
        }
        .form {
          padding: 20px 0 10px;
          .tip {
            padding-left: 50px;
            height: 16px;
            font-size: 12px;
            margin: 0 0 15px;
            color: #666;
          }
          .forget {
            cursor: pointer;
            height: 12px;
            font-size: 12px;
            color: #666;
            text-align: right;
          }
        }
        .btn-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          button {
            cursor: pointer;
            width: 280px;
            height: 40px;
            font-size: 14px;
            border: none;
            border-radius: 0.2rem;
            background: rgba($color: #000000, $alpha: 0);
            font-weight: bold;
          }
          .login {
            margin-bottom: 20px;
            color: #fff;
            background: #1872bb;
          }
          .register {
            color: #1872bb;
            // background: #fff;
            border: 1px solid #1872bb;
          }
        }
        .set-password {
          .title {
            color: #1872bb;
            text-align: center;
            border: none;
            font-size: 14px;
            font-weight: bold;
          }
          .yl-input {
            margin-bottom: 30px;
          }
          .yl-input:last-child {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}
</style>
